import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import * as Pages from "./pages";
import { Footer, Header, Redirect } from "components";
import { AppVersionError } from "exceptions";
import { useQuery } from "hooks";
import AppContext from "AppContext";

const Container = () => {
  const query = useQuery();
  const { setCurrentExhibition, setCurrentVisitedUrls, setUtm } = useContext(AppContext);

  useEffect(() => {
    if (query?.utm_source)
      setUtm({
        utm_medium: query.utm_medium,
        utm_source: query.utm_source,
        utm_id: query.utm_id,
        utm_content: query.utm_content,
        utm_term: query.utm_term,
        utm_campaign: query.utm_campaign,
      });
  }, [query]);

  useEffect(() => {
    if (query.evento) setCurrentExhibition(query.evento);
  }, [query.evento]);

  useEffect(() => {
    try {
      setCurrentVisitedUrls((state) => {
        const tmp = [...state];
        tmp.push({ url: window.location.href, settedAt: new Date() });
        return tmp;
      });
    } catch (error) {
      console.error("VisitedUrlsError", error);
    }
  }, []);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AppProvider = () => {
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const [utm, setUtm] = useState(() => {
    try {
      const value = localStorage.getItem("utm");
      return JSON.parse(value);
    } catch (error) {
      return {};
    }
  });
  const [currentExhibition, setCurrentExhibition] = useState(localStorage.getItem("evento"));
  const [currentVisitedUrls, setCurrentVisitedUrls] = useState(JSON.parse(localStorage.getItem("visitedUrls")) || []);

  useEffect(() => {
    if (utm) localStorage.setItem("utm", JSON.stringify(utm));
  }, [utm]);

  useEffect(() => {
    localStorage.setItem("evento", currentExhibition);
  }, [currentExhibition]);

  useEffect(() => {
    localStorage.setItem("visitedUrls", JSON.stringify(currentVisitedUrls));
  }, [currentVisitedUrls]);

  const renderSigUpRoutes = useCallback((path) => {
    return (
      <Route path={path}>
        <Route index={true} element={<Pages.SignUpGeneral />} />
        <Route path="inicio" element={<Pages.SignUpGeneral />} />
        <Route path="inicio/:_id" element={<Pages.SignUpGeneral />} />
        <Route path="endereco/:_id" element={<Pages.SignUpAddress />} />
        <Route path="documentacao/:_id" element={<Pages.SignUpDocuments />} />
        <Route path="sucesso" element={<Pages.SignUpSuccess />} />
        <Route path="*" element={<Navigate to="inicio" replace={true} />} />
      </Route>
    );
  }, []);

  return (
    <AppContext.Provider
      value={{
        isMobileView,
        currentExhibition,
        setCurrentExhibition,
        currentVisitedUrls,
        setCurrentVisitedUrls,
        utm,
        setUtm,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<Container />}>
            <Route index={true} element={<Pages.Home />} />

            <Route path="indique-e-ganhe">
              <Route index={true} element={<Pages.IndicationsGenerate />} />
              <Route path="compartilhe" element={<Pages.IndicationsShare />} />
            </Route>

            <Route path="click-rec" element={<Pages.ClickRec />} />
            <Route path="ClickRec" element={<Navigate to="/click-rec" replace={true} />} />

            {renderSigUpRoutes("quero-economizar")}
            {renderSigUpRoutes("fazendacervejeira")}
            {renderSigUpRoutes("black-da-click")}

            <Route path="indicacoes/:_id" element={<Pages.IndicationsIndicated />} />
          </Route>

          <Route path="estatuto" element={<Redirect href="/downloads/01_Aditivo_-_Cons._Clicklivre_-_30.06.2023.pdf" />} />

          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </BrowserRouter>
      <AppVersionError />
    </AppContext.Provider>
  );
};

export default AppProvider;
